<template>
  <img src="../assets/images/title.svg" alt="Пропостание">
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
img{
  width: 100%;
}
</style>