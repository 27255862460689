<template>
  <img src="../assets/images/photos/12-lisytsky.jpg" alt="">
</template>

<script>
export default {
  name: "Slide"
}
</script>

<style scoped>
img {
  width: 100%;
  border-radius: var(--radius);
}

</style>