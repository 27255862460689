<template>
    <img src="@/assets/images/map/map.jpg" alt="Карта Эко-парка Вереск">
</template>

<script>
export default {
  name: "Map"
}
</script>

<style scoped>

img {
  border-radius: var(--radius);
  width: 100%;

}

</style>